//Call Angular component's function from Android/iOS WebView
function barcodeRead(barcode) {
    let inputDomElement = document.getElementById('barcodeInput');
    if (inputDomElement) {
        inputDomElement.value = barcode;

        // When change input value, fire this trigger
        inputDomElement.dispatchEvent(new Event('change'));
    }
}

function takeAPhotoResult(base64Result) {
    let inputDomElement = document.getElementById('takePhotoInput');
    if (inputDomElement) {
        inputDomElement.value = base64Result;

        // When change input value, fire this trigger
        inputDomElement.dispatchEvent(new Event('change'));
    }
}

function sumUpCallback(smpStatus, smpTxCode, foreignTxId) {
    let inputDomElement = document.getElementById('sumUpInput');
    if (inputDomElement) {
        inputDomElement.value = JSON.stringify({
            smpStatus,
            smpTxCode,
            foreignTxId,
        });

        // When change input value, fire this trigger
        inputDomElement.dispatchEvent(new Event('change'));
    }
}
